export const useExperimentsDiaryWide = () => {
  var storedValue = useCookie("useExperimentsDiaryWide",{maxAge: 60 * 60 * 24 * 31 * 6}).value
  var data = ref(storedValue ? true : false);

  watch(data, (newValue) => {
    // alert(newValue);
    console.log('set cookie useExperimentsDiaryWide', newValue);
    useCookie("useExperimentsDiaryWide",{maxAge: 60 * 60 * 24 * 31 * 6}).value = newValue;
  });

  return data;
}


export const useExperimentsRegion = () => {
  var storedValue = useCookie("useExperimentsRegion",{maxAge: 60 * 60 * 24 * 31 * 6}).value
  var data = ref(storedValue ? storedValue : 'auto');

  watch(data, (newValue) => {
    useCookie("useExperimentsRegion",{maxAge: 60 * 60 * 24 * 31 * 6}).value = newValue;
  });

  return data;
}


export const usePageBottomMargin = () => useState('usePageBottomMargin', () => 0)